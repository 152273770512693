<template>
  <div class="show-detail-wrap">
    <headNav active="2"></headNav>
    <div class="show-detail-contents w1200">
      <h2 class="h2">
        {{ detailInfo.title }}
      </h2>
      <div class="flex between show-detail-info">
        <div class="left">
          <img :src="`${state.baseUrl}${detailInfo.thumb}`" alt="" />
        </div>
        <div class="right fs14 tal">
          <p>{{$t('showDetail.time')}}:</p>
          <p>{{ detailInfo.showtime }}</p>
          <p class="mt28">{{$t('showDetail.venue')}}:</p>
          <p>{{ detailInfo.venue }}</p>
          <p class="mt28">{{$t('showDetail.guest')}}</p>
          <p>{{ detailInfo.actor }}</p>
          <p class="mt28">{{$t('showDetail.child')}}:</p>
          <p>{{ detailInfo.kidtips }}</p>
          <p class="mt28">{{$t('showDetail.prohibit')}}:</p>
          <p>{{ detailInfo.prohibitedtips }}</p>
          <div class="flex mt28">
            <p class="mr60">
              {{$t('showDetail.duration')}}:<br />
              {{ detailInfo.showduration }}
            </p>
            <p class="mr60">
              {{$t('showDetail.admission')}}:<br />
              {{ detailInfo.admissiontime }}
            </p>
            <p>
              {{$t('showDetail.deposit')}}:<br />
              {{ detailInfo.deposit }}
            </p>
          </div>

          <a :href="detailInfo.showurl" target="__blank" class="status active" v-if="detailInfo.showstatus == 'selling'">
            {{showstatus[detailInfo.showstatus]}}
          </a>
          <button class="status" v-else>
            {{showstatus[detailInfo.showstatus]}}
          </button>
        </div>
      </div>

      <div class="show-h2 tal flex v-center">
        <span class="fs24">{{$t('showDetail.introduction')}}</span>
        <span class="fs12 pl8">Performance introduction</span>
      </div>

      <div class="show-detail-text">
        <div v-html="contents"></div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      detailInfo: {},
      showstatus:{
        init:'未开票',
        selling: '已开票',
        end:'已结束'
      }
    };
  },
  computed: {
    state() {
      return this.$store.state;
    },
    contents () {
      let content = this.detailInfo.content2 || ''
      let newStr = content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, capture) {
        if(!/http|https/.test(capture)) {
          return `<img style="max-width: 100%;" src="https://www.suzhousco.com${capture}" alt="" />`
        } else {
          return '<img style="max-width: 100%;" src="'+capture+'" alt="" />';
        }
      })
      return newStr
    }
  },
  created() {
    this.id = this.$route.params.id || 0;
    this.getNewsDetail();
  },
  methods: {
    // 获取演出列表
    getNewsDetail() {
      this.$ajax
        .get("/cms/api/showdetail", {
          id: this.id,
        })
        .then((res) => {
          if (res.code == "200") {
            this.detailInfo = res;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.show-detail-wrap {
  background: #eee;
}
.show-detail-contents {
  padding-top: 180px;
  .show-h2 {
    height: 24px;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      height: 1px;
      background: #c0c4cc;
      width: 100%;
      flex: 1;
      margin-left: 8px;
    }
  }
}

.show-detail-text {
  padding-top: 100px;
  p {
    line-height: 1;
    margin: 0;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .left {
    width: 450px;
    min-height: 940px;
    padding: 32px;
    background: #fff url("../assets/color-bg.png") no-repeat 50% 0;
  }

  .right {
    width: 610px;
  }

  img {
    max-width: 100%;
  }
}
.show-detail-info {
  padding: 80px 0;
  .left {
    width: 340px;
    height: 460px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .right {
    width: 802px;
    position: relative;
    p {
      margin-bottom: 5px;
    }
    .status {
      position: absolute;
      right: 0;
      width: 145px;
      height: 36px;
      color: #fff;
      background: #909399;
      border: none;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      line-height: 36px;
      &.active {
        background: #b49a71;
      }
    }
  }
}
</style>
