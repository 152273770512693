<template>
  <div class="show-wrap">
    <headNav active="2"></headNav>
    <div class="show-contents w1200">
      <div class="item flex between" v-for="(item,key) in showList" :key="key">
        <div class="pic">
          <img :src="`${state.baseUrl}${item.thumb}`" alt="">
        </div>
        <div class="text tal">
          <h2 class="ellipsis2 fs20 mt40 h2">
            {{item.title}}
          </h2>
          <p><b>{{$t('show.time')}} |</b> {{item.showtime}}</p>
          <p><b>{{$t('show.venue')}} |</b> {{item.venue}}</p>
          <p><b>{{$t('show.guest')}} |</b> {{item.actor}}</p>

          <a @click="showDetail(item)" class="btn" target="_blank">演出详情</a>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      showList: []
    }  
  },
  created(){
    this.getShowList()
  },
  computed:{
    state() {
      return this.$store.state;
    }
  },
  methods:{
    showDetail(item) {
      this.$router.push({ name: 'show-detail', params: { id: item.id }})
    },

    getShowList() {
      this.$ajax
        .get("/cms/api/showlist", {})
        .then((res) => {
          if (res.code == "200") {
            this.showList = res.data
            console.log(this.showList);
          }
        });
    },
  }
};
</script>

<style scoped lang="scss">
.show-wrap {
  background-color: #eee;
}
.show-contents {
  background: url("../assets/show-title.png") no-repeat 95px 175px;
  padding: 390px 120px 20px 130px;
  .item {
    margin-bottom: 100px;
    .pic {
      width: 360px;
      height: 480px;
      // background: red;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    .text {
      width: 490px;
      position: relative;
      h2 {
        position: relative;
        padding-left: 22px;
        margin-bottom: 55px;
        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 7px;
          height: 100%;
          background: #b49a71;
          left: 0;
          top: 0;
        }
      }
      p{
        font-size: 16px;
        color:#303133;
        margin-bottom: 30px;
      }
      .btn{
        width: 200px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        background: #b49a71;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        bottom: 10px;
        right: 0;
        border-radius: 4px;
        border: none;
      }
    }
  }
}
</style>
